@import "~@/erp/styles/variables/variables.scss";
































































































































































































































.detail-content {
  padding: 0 20px 90px;
}
.header-text {
  font-size: 14px;
  color: #606266;
}
.fs14 {
  font-size: 14px;
}
