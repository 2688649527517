@import "~@/erp/styles/variables/variables.scss";





























































































































































































.tr {
  text-align: right;
}
.button-wrapper {
  text-align: center;
}
.product-info {
  display: flex;
  padding: 10px;
  text-align: left;
  .pro-item {
    width: 100%;
    white-space: pre-wrap;
    margin-bottom: 5px;
  }
  .left {
    flex: 1;
  }
  p {
    display: flex;
    flex-direction: column;
    .el-tag {
      margin-bottom: 5px;
      max-width: 90px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
